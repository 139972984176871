import React, { useEffect } from 'react'
import LoginForm from '../components/LoginForm'
import GoogleTest from '../components/GoogleTest'
import TestLogin from '../components/TestButton'
import LoginTest from '../components/LoginTest'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from 'react-router-dom'



function Login() {
  const idd = '283881869757-fblgo1735t45eso5u0or1c67h1h45e9p.apps.googleusercontent.com'

  return (
    <>
    <LoginTest/>
      {/* <div style={{ display: 'flex', marginTop: 150, alignItems: 'center', justifyContent: 'center' }}> */}
        {/* <LoginForm/> */}
        {/* <GoogleTest/> */}
        {/* <TestLogin/> */}
      {/* </div> */}
    </>
  )
}

export default Login


