import React, { useEffect, useState } from 'react'
import './argon-dashboard.css'
import TableRow from '../components/TableRow'
import AddData from '../components/AddData'
import Navbar from '../components/Navbar'
import EmployeeCard from '../components/EmployeeCard'
import instance from '../utils/axios'
import sumYearsAndMonths from '../utils/sumYears'
import formatDate from '../utils/formatDate'
import { Link } from 'react-router-dom'
import Cookies from "js-cookie";
const EmployeeScreen = ({email,picture}) => {
    // console.log(picture,"this is email from employeescreen");
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [skills, setSkills] = useState([]);
    const [updatedSkills, setUpdatedSkills] = useState(true);
    // const [years, setYears] = useState(0);
    // const [months, setMonths] = useState(0);
    const [lastUpdated, setLastUpdated] = useState('');
    const [currentlyEditing, setCurrentlyEditing] = useState(null);
    const[ refresh, setRefresh] = useState(false)
    useEffect(() => {
        (async () => {
            try {
                const token = Cookies.get("token");
               
                // console.log(token,"this is token from employeescreen");
                const queryParams = { "email": email }
                const response = await instance.get('/employee/employeeByEmail', {
                    params: queryParams,
                  
                }
            )
            // console.log(response,"this is test response");
                setEmployeeDetails(response.data.data)
                // console.log(response.data.data)
            } catch (error) {
                console.log(error)

            }
        })();

    }, [updatedSkills, refresh])
    useEffect(() => {
        (async () => {
            try {
                const response = await instance.post('/employee/getEmployeeSkills', {
                    skills: employeeDetails?.skillIds,
                })
                setSkills(response.data.data)
                // console.log(response.data.data)
    

                const dateArray = response?.data?.data?.map(item => new Date(item.updatedAt));
                const latestTimestamp = new Date(Math.max(...dateArray));
                const updatedAt=formatDate(latestTimestamp);
                setLastUpdated(updatedAt);  
            //     let years1 = 0;
            //     let months1 = 0;

            //     // Use forEach to iterate through the array and calculate the sums
            //     response?.data?.data?.forEach(item => {
            //         years1 += item.years;
            //         months1 += item.months;
            //     });
            //    let output=sumYearsAndMonths(years1, months1);
            //     setYears(output.years);
            //     setMonths(output.months);
            } catch (error) {
                console.log(error)

            }
        })();
    }, [employeeDetails,refresh])
    const handleToggleEdit = (rowId) => {
        setCurrentlyEditing(rowId);
    };
    
    return (
        <div className="main-content">
            <Navbar name={employeeDetails?.name} role={employeeDetails?.role} picture={picture} />
            <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8 ">
                <div className="container-fluid  ">
                    <div className="header-body  ">
                        <EmployeeCard setRefresh={setRefresh} employeeDetails={employeeDetails} lastUpdated={lastUpdated} />
                    </div>
                </div>
            </div>
            <div className="container-fluid mt--7">
                {/* Table */}
                <div className="row">
                    <div className="col">
                        <div className="card shadow">
                            <div className="card-header border-0">
                                <h3 className="mb-0">Skillset</h3>
                            </div>
                            <div className="table-responsive">
                                <table className="table align-items-center table-flush">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Years</th>
                                            <th scope="col">Months</th>
                                            <th scope="col">Last Used</th>
                                            <th scope="col" >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            skills.map((item, index) => {
                                                // if(item.deleted)
                                                //   return null
                                                return <TableRow
                                                userId={employeeDetails?._id}
                                                    key={index}
                                                    item={item}
                                                    id={item._id}
                                                    isEditing={currentlyEditing === item._id}
                                                    onToggleEdit={handleToggleEdit}
                                                    setUpdatedSkills={setUpdatedSkills}
                                                    setRefresh={setRefresh}
                                                />
                                            })
                                        }
                                        <AddData userId={employeeDetails?._id} setUpdatedSkills={setUpdatedSkills} />

                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="card-footer py-4">
                                <nav aria-label="...">
                                    <ul className="pagination justify-content-end mb-0">
                                        <li className="page-item disabled">
                                            <a className="page-link" href="#" tabIndex={-1}>
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </a>
                                        </li>
                                        <li className="page-item active">
                                            <a className="page-link" href="#">
                                                1
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">
                                                2 <span className="sr-only">(current)</span>
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">
                                                3
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* Footer */}
                
            </div>
        </div>

    )
}

export default EmployeeScreen