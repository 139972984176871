import React, { useEffect, useState } from "react";
import instance from "../utils/axios";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";

const AddData = ({ userId, setUpdatedSkills }) => {
  const [validForm, setValidForm] = useState(true);
  let today = new Date();
  const [data, setData] = React.useState({
    userId: userId,
    skillName: "",
    years: "",
    months: "",
    lastUsed: "",
  });
  const [skills, setSkills] = React.useState([]);
  useEffect(() => {
    (async () => {
      try {
        const response = await instance.get("/skillSet/getDistinctSkills");
        let data = response.data.data;
        // console.log(data)
        setSkills(data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  const handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target.value)
    setData({
      ...data,
      [e.target.name]: e.target.value,
      userId: userId,
    });
  };
  const handleValid = () => {
    const currentDate = new Date();
    const lastUsed = new Date(data.lastUsed);
    const months = data?.months === "" ? 0 : parseInt(data?.months);
    const years = data?.years === "" ? 0 : parseInt(data?.years);
    return data.skillName !== null && data.skillName !== "" && months <= 11 && years <= 25 && lastUsed <= currentDate &&
    !(years === 0 && months === 0);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(data,"test")
    const valid = handleValid();
    setValidForm(valid);
    // console.log("valid = ", valid);
    if (valid) {
      if (skills.includes(data?.skillName)) {
        const output = await instance.post("/employee/addSkill", data);
        console.log("this is output", output)
        if (output) {
          setUpdatedSkills((prev) => !prev);

          setData({
            userId: userId,
            skillName: "",
            years: "",
            months: "",
            lastUsed: "",
          });
          if (output?.data?.status === "skillExists")
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Error',
              text: output?.data?.message || "Skill exists",
              showConfirmButton: false,
              timer: 2000
            })
          else if (output?.data?.status === "success")
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'success',
              text: output?.data?.message || "Skill Added Successfully",
              showConfirmButton: false,
              timer: 2000
            })

        }
      } else {
        setValidForm(false);
        // Swal.fire({
        //   position: 'middle',
        //   icon: 'error',
        //   title: 'Error',
        //   text: "Please select skill from dropdown",
        //   showConfirmButton: false,
        //   timer: 2000
        // })
      }
      // alert(JSON.stringify(data))
    } else {
    }
    // setValidForm(true);
  };
  return (
    <>
      <tr>
        {/* Skill n */}

        <td className="autocomplete">
          {/* <Autocomplete
            freeSolo
            onChange={(e, value) => {
              if (value) {
              setData({
                ...data,
                skillName: value,
              });
            }
            }}
            inputValue={data.skillName}
            onInputChange={(event, newInputValue) => {
              if(newInputValue)
              setData({
                ...data,
                skillName: newInputValue,
              });
              else if(event?.target?.value === ''){
                setData({
                  ...data,
                  skillName: "",
                })
              }
            }}
            // label="Skill Name"
            options={skills}
            getOptionLabel={(option) => (option && option.label) ? option.label : ''}
            // value={data.skillName}
            sx={{ width: "100%" }}
            // className="input-field"
            renderInput={(params) => (
              <TextField {...params} label="* Skill Name" size="small" />
            )}
          /> */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={data.skillName}
            onChange={(event, newValue) => {
              // setValue(newValue);
              setData({
                ...data,
                skillName: newValue,
              });
            }}
            onInputChange={(event, newInputValue) => {

              // If a valid option is entered, update the state
              setData({
                ...data,
                skillName: newInputValue,
              });
              // Reset any error state or perform other actions for validation success

            }}
            options={skills}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Skill Name" size="small" />}
          />
          {/* <input value={data.skillName} type="text" name="skillName" onChange={handleChange} className="input-field" placeholder="Skill Name" /> */}
        </td>

        {/* Years of experience */}
        <td>
          <input
            value={data.years}
            type="number"
            name="years"
            onChange={handleChange}
            className="input-field"
            placeholder="Years of Experience"
            min="0"
            max="25"
          />
        </td>
        {/* Months of experience */}
        <td>
          <input
            value={data.months}
            type="number"
            name="months"
            onChange={handleChange}
            className="input-field"
            placeholder="Months of Experience"
            max="11"
            min="0"
          />
        </td>

        {/* Last used date */}
        <td>
          <input
            value={data.lastUsed}
            type="date"
            name="lastUsed"
            onChange={handleChange}
            className="input-field"
            placeholder="Last Used Date"
          />
        </td>

        <td>
          <button className="blue-button" onClick={handleSubmit}>
            ADD
          </button>
        </td>
      </tr>

      {/* Error message displaying here */}
      <tr>
        {
          (validForm === false && (!skills.includes(data?.skillName)) )? <td className="text-red">* Please select a skill from dropdown</td>:<td></td>
        }

        {validForm === false &&
          (parseInt(data?.years) > 25 || data.years === "") ? (
          <td className="text-red">* Enter a year less than 25</td>
        ) : (
          <td></td>
        )}

        {validForm === false &&
          (parseInt(data?.months) > 11 || data.months === ""||(parseInt(data.years) === 0 && parseInt(data?.months) === 0)) ? (
            
          <td className="text-red">* Enter a valid month
          {(parseInt(data.years) === 0 && parseInt(data.months) === 0)&&" atleast 1"}
          {parseInt(data?.months) > 11&&" less than 12"}</td>
        ) : (
          <td></td>
        )}

        {validForm === false &&
          (new Date(data?.lastUsed) > today) ? (
          <td className="text-red">* Can't enter future dates</td>
        ) : (
          <></>
        )}
        {validForm === false &&
          (data.lastUsed === "") ? (
          <td className="text-red">* Enter a date</td>
        ) : (
          <></>
        )}
      </tr>
    </>
  );
};

export default AddData;
