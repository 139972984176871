function reverseDate(inputDate) {
  const parts = inputDate.split("-");
  if (parts.length === 3) {
    const [year, month, day] = parts;
    return `${day}-${month}-${year}`;
  } else {
    return "Invalid date format";
  }
}

export default reverseDate