import React from 'react';

const LoginTest = () => {
  // const onSuccess =  () => {
  //   // const userProfile = response.profileObj;
  //   // console.log(response.credential, "this is userProfile");
  //   // Cookies.set('token', response.credential, { expires: 7 }); // 'expires' is an optional parameter to specify the cookie's expiration date
  //   window.open(`${process.env.REACT_APP_BACKEND}/user/auth/google/callback`, "_self");
  //   // window.location.href = '/';
  // };

  // --------------------------if using googlelogin from react o-auth google--------------------------
  const onSuccess = async () => {
    // const { type } = response.event;
    // const userProfile = response.profileObj;
    // console.log(response, "this is userProfile");
    // Cookies.set('token', response.credential, { expires: 7 }); // 'expires' is an optional parameter to specify the cookie's expiration date
    window.open(`${process.env.REACT_APP_BACKEND}/user/auth/google`, "_self");
    // window.location.href = '/';
  };


  const onFailure = (error) => {
    // Handle the error
    alert('Login failed: ' + error.message);
  };
  return (
    <>
      {/* header */}
      <div className="header bg-gradient-primary py-7 py-lg-8">
        <div className="container">
          <div className="header-body text-center mb-7">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6">
                <h1 className="text-white">Welcome!</h1>
                <p className="text-lead text-light">
                  A web app that assists employees in cataloging and presenting their programming and IT skills.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            x={0}
            y={0}
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
      {/* login form */}
      <div className="container mt--8 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7">
            <div className="card bg-secondary shadow border-0">
              <div className="card-header bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <small>Sign in with</small>
                </div>

                <div className="btn-wrapper text-center"  onClick={onSuccess} >
                  
                  <a href="#" className="btn btn-neutral btn-icon" >
                    <span className="btn-inner--icon">
                        <img src="/images/google.svg" />
                        </span>
                      <span className="btn-inner--text">Google</span>
                      {/* <GoogleLogin onSuccess={onSuccess} onFailure={onFailure} /> */}
                  </a>
                   
                </div>
              </div>
              <div className="card-body px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4 ">
                  <small className='font-weight-bold ml-1 text-primary'>@ SEQATO 2023</small>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

    </>

  )
}

export default LoginTest