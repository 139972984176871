import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import axios from "axios";
import instance from "../utils/axios";
import Cookies from 'js-cookie';

const GoogleTest = () => {
  const [user, setUser] = useState(null);

  const onSuccess =  (response) => {
    // console.log("hello success");
    // const userProfile = response.profileObj;
    // console.log(response.credential, "this is userProfile");
    Cookies.set('token', response.credential, { expires: 7 }); // 'expires' is an optional parameter to specify the cookie's expiration date
    window.open("http://localhost:5000/user/auth/google", "_self");
    // window.location.href = '/';
  };

  const onFailure = (error) => {
    // Handle the error
    alert('Login failed: ' + error.message);
  };

  return (
    
      <GoogleOAuthProvider clientId={'283881869757-fblgo1735t45eso5u0or1c67h1h45e9p.apps.googleusercontent.com'}>
        <GoogleLogin onSuccess={onSuccess} onFailure={onFailure} />
      </GoogleOAuthProvider>

  );
};

export default GoogleTest;
