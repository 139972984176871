import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import EmployeeTableRow from './EmployeeTableRow';
import instance from '../utils/axios';
import formatDate from '../utils/formatDate';
import Swal from "sweetalert2";

const AdminTableRow = ({ item, isEditing, onToggleEdit , currentPage,setRefresh}) => {

    // console.log("This is item", onToggleEdit) // Check here
    // console.log("This is item", item) // Check here
    const dateArray = item?.skills?.map(item => new Date(item.updatedAt));
    const latestTimestamp = new Date(Math.max(...dateArray));
    const updatedAt = formatDate(latestTimestamp);
    // console.log(item?.basicDetails?.totalExp,"basic details");
    const [employeeSkillList, setEmployeeSkillList] = useState([])
    ///////validation////////
    const [validForm, setValidForm] = useState(true);
    const [error,setError]=useState(false)

    // Use effect added to solve the duplicate id pagination error message issue
    useEffect(()=>{
        setError(false)
    },[item])

    const [data, setData] = useState({
        employeeId: item?.basicDetails?.employeeId,
        name: item?.basicDetails?.name,
        status: item?.basicDetails?.status,
        role: item?.basicDetails?.role,
        experience:item?.basicDetails?.totalExp
    })
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success m-1",
            cancelButton: "btn btn-danger m-1",
            // actions :"btn-spacing-sweet-alert mr-5"
        },
        buttonsStyling: true,
    });
    useEffect(() => {
        setData({
            employeeId: item?.basicDetails?.employeeId,
            name: item?.basicDetails?.name,
            status: item?.basicDetails?.status,
            role: item?.basicDetails?.role,
            experience:item?.basicDetails?.totalExp
        })
    }, [item])
    const [viewEmployeeDetails, setViewEmployeeDetails] = useState(false);

    /////////validation/////////
    const handleValid = () => {
        return data?.name !== ""
    }
    const handleChange = (e) => {
        e.preventDefault();
        // console.log(e.target.value)
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleViewEmployeeSkills = async (e) => {
        e.preventDefault();
        // have a clarity here if need to call this api 
        // const skills = item?.basicDetails?.skillIds
        setEmployeeSkillList(item?.skills)
        setViewEmployeeDetails(true)
        // const data = await instance.post('/employee/getEmployeeSkills',{skills:skills})
        // if(data?.data?.status === "success"){
        //     console.log("This is employee skill list", data?.data)

        // }else{
        //     // add a sweet alert here
        // }
    }
    const handleEditClick = () => {
        setRefresh(prev=>!prev)
        onToggleEdit(item?.basicDetails?._id); // Toggle editing state for the current row
    };

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        // console.log(JSON.stringify(data));
        // console.log("This is employee id", item?.basicDetails?._id)
        const newData = data
        // console.log("THis is new data", newData)
        const userId = item?.basicDetails?._id
        const valid = handleValid()
        setValidForm(valid)
        // console.log(valid, "this is valid");
        if (valid) {
            try{
                const editData = await instance.put(`/employee/editEmployee/${userId}`, newData)
                if (editData?.data?.status === 'success') {
                    // Add sweet alert here
                    // alert('Success')
                    Swal.fire({
                        position: 'middle',
                        icon: 'success',
                        title: 'success',
                        text: "Employee Edited Successfully",
                        showConfirmButton: false,
                        timer: 3000
                    })
                } else {
                    // alert("Couldn't edit the employee")
                    Swal.fire({
                        position: 'middle',
                        icon: 'error',
                        title: 'Error',
                        text: "Employee Couldn't be Edited",
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
                onToggleEdit(null);
            }catch(err){
                // console.log(err?.response?.data?.message,"this is error")
                setValidForm(false)
                setError(true)
                // Swal.fire({
                //     position: 'middle',
                //     icon: 'error',
                //     title: 'Error',
                //     text: err?.response?.data?.message,
                //     showConfirmButton: false,
                //     timer: 3000
                // })
            }
           
        }


    }

    return (
        <>
            <tr>
                <th >
                    {isEditing ?
                        <input type="text"
                            value={data.employeeId}
                            onChange={handleChange}
                            name="employeeId"
                            className="input-field"
                            placeholder="Employee Id" />
                        : data.employeeId}
                </th>
                <th>
                    {isEditing ?
                        <input type="text"
                            value={data.name}
                            onChange={handleChange}
                            name="name"
                            className="input-field"
                            placeholder="Employee Name" />
                        : data.name}
                </th>

                <th>
                    {item?.skills?.length > 0 ? item?.skills.map((item, index) => index < 4 ? (item?.skillName + ", ") : "") : 'Not added'}

                </th>
                <td>
                    {isEditing ?
                        <select className="input-field" name='status' value={data.status} onChange={handleChange}>
                            <option value="active">Active</option>
                            <option value="inActive">InActive</option>
                        </select> :
                        <span className="badge badge-dot mr-4">
                            <i className={data.status === "active" ? "bg-success" : "bg-danger"}></i>
                            {data.status}
                        </span>}

                </td>
                <th>
                    {isEditing ?
                        <select className="input-field" name='role' value={data.role} onChange={handleChange}>
                            <option value="employee">Employee</option>
                            <option value="admin">Admin</option>
                        </select> :
                        data.role
                    }
                </th>
                <th>
                    {updatedAt === "NaN-NaN-NaN" ? "Not added skills" : updatedAt}
                </th>
                <td>
                    {
                        isEditing ?
                            <>
                                <a
                                    className="btn btn-sm btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    onClick={handleSubmitEdit}
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i class="fas fa-check" style={{ color: "#000000" }}></i>

                                </a>
                                <a
                                    className="btn btn-sm btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setValidForm(true)
                                        setData({
                                            employeeId: item?.basicDetails?.employeeId,
                                            name: item?.basicDetails?.name,
                                            status: item?.basicDetails?.status,
                                            role: item?.basicDetails?.role
                                        })
                                        onToggleEdit(null);
                                    }}
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i class="fas fa-times" style={{ color: "#000000" }}></i>
                                </a>
                            </>
                            :
                            <>
                                <a
                                    className="btn btn-sm btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleEditClick();

                                    }}
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="fas fa-edit" style={{ color: "#000000" }}></i>
                                </a>
                                <a
                                    className="btn btn-sm btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    onClick={handleViewEmployeeSkills}
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="fas fa-eye" style={{ color: "#000000" }}></i>
                                </a>
                            </>
                    }
                </td>
            </tr>
            <tr>

            {validForm === false && error ? (
                    <>
                    <td className="text-red">* Duplicate id not allowed</td>
                    </>
                ) : (
                    ""
                )}
              
                {validForm === false && data?.name === "" ? (
                    <>
                      <td></td>
                    <td className="text-red">* Name cannot be empty</td>
                    </>
                ) : (
                    ""
                )}
            </tr>

            {viewEmployeeDetails && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <Modal centered show={viewEmployeeDetails} onHide={() => setViewEmployeeDetails(false)} dialogClassName="my-modal" aria-labelledby="contained-modal-title-vcenter">
                        <Modal.Header className="p-2 ml-2" closeButton>
                            <Modal.Title>
                                Employee Name: {data.name}
                            </Modal.Title>
                            
                        </Modal.Header>
                        <h4 className='ml-3'>
                                Experience: {data?.experience?.years?data?.experience?.years:0} years {data?.experience?.months?data?.experience?.months:0} months 
                            </h4>
                        <div className="container List" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <EmployeeTableRow skillSetArray={employeeSkillList} />
                        </div>

                    </Modal>
                </div>
            )}
        </>
    )
}

export default AdminTableRow