import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import AdminTableRow from '../components/AdminTableRow';
import instance from '../utils/axios';
import ListCourseTable from '../components/ListCourseTable';
import { Pagination } from '@mui/material';

const ListCoursesScreen = ({name,role,picture}) => {
    const [currentlyEditing, setCurrentlyEditing] = useState(null);
    const [openAddEmployee, setOpenAddEmployee] = useState(false);
    const [allEmployees, setAllEmployees] = useState([ ]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [searchList, setSearchList] = useState({
      employeeId: "",
      name: "",
      course: ""
      // skillAdded false means skill is not added
    });
  
    const handleToggleEdit = (rowId) => {
      // console.log("This is row id", rowId)
      setCurrentlyEditing(rowId);
    };
  
    const handlePagination = (event, value) => {
      setCurrentPage(value);
      // setRefresh(!refresh);
      // alert(value)
    };
  
    useEffect(() => {
      const fetchAllEmployees = async () => {
        try {
  
          // console.log("This is search list ", searchList)
          const data = await instance.post("/course/getAllCourse", {
            page: currentPage - 1,
            dataCount: 10,
            searchList: searchList,
          });
          // console.log("This  is employee list ", data?.data?.data );
          // Api response and search list is correct . Search issue is below
          if(data){
            // Search issue is here while setting setAllEmployees() value updating on next refresh.. 
            // In admin table item is consoled even before the searchList is consoled
            // Need to fix that
            setAllEmployees(data?.data?.data);
            setTotalPages(data?.data?.totalPages);
          }
          else{
            console.log("Employee list not found")
          }
          
        } catch (error) {
          console.log("Error in fetchAllEmployees", error);
        }
      };
      fetchAllEmployees();
    }, [refresh, currentPage, searchList]);
  const [noSkills,setNoSkills]=useState(true);
  const handleSearch =  (e) => {
    e.preventDefault();
    let value = e.target.value;
    let name = e.target.name;
      setSearchList({
      ...searchList,
      [name]: value,
    });
    setCurrentPage(1);
  };
  return (
    <div className="main-content">
    <Navbar name={name} role={role} picture={picture} screen="Admin" userData="Admin"/>
    <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8 ">
      {/* ------ Add Employee -------- */}
     
      {/* --------- Filter ------------- */}
      <div className="container">
        <div className="input-row">
        <div className="input-container">
              <label className="input-label" htmlFor="employeeId">
                Employee ID :
              </label>
              <input
                type="text"
                id="employeeId"
                className="input-field"
                placeholder="Search by Employee ID"
                name="employeeId"
                onChange={handleSearch}
              />
            </div>

            <div className="input-container">
              <label className="input-label" htmlFor="name">
                Name :
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="input-field"
                placeholder="Search by Name"
                onChange={handleSearch}
              />
            </div>

            <div className="input-container">
              <label className="input-label" htmlFor="skill">
                Courses :
              </label>
              <input
                type="text"
                id="skill"
                name="course"
                className="input-field"
                placeholder="Search by Courses"
                onChange={handleSearch}
              />
            </div>
 
        </div>
      </div>
      {/* ...................................... */}
    </div>
    <div className="container-fluid mt--7">
        <div className="row">
          <div className="col">
            <div className="card shadow">
              <div className="card-header border-0">
                <h3 className="mb-0">Employee Courses List</h3>
              </div>
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Employee ID</th>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Courses</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {allEmployees?.map((item, index) => {
                      return (
                        <ListCourseTable
                          key={index}
                          item={item}
                          // isEditing={
                          //   currentlyEditing === item?.basicDetails?._id
                          // }
                        //   onToggleEdit={handleToggleEdit}
                        //   currentPage
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="card-footer py-4 d-flex justify-content-end">
                {/* <nav aria-label="...">
                  <ul className="pagination justify-content-end mb-0">
                    <li className="page-item disabled">
                      <a className="page-link" href="#" tabIndex={-1}>
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2 <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav> */}
                <Pagination
                  count={totalPages}
                  // color="primary"
                  className="pagination"
                  sx={{
                    '.MuiPaginationItem-page.Mui-selected': {
                      backgroundColor: '#00D4FF',
                      color: 'white',
                    },
                    '& .MuiPaginationItem-page:hover': {
                      backgroundColor: '#00D4FF',
                      color: 'white',
                    },
                  }}
                  page={currentPage}
                  onChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListCoursesScreen