function formatDate(date) {
    // Extract date components
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Construct the formatted date
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
}

export default formatDate;