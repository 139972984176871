import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import EditExperience from './EditExperience';

const EmployeeCard = ({employeeDetails,lastUpdated, setRefresh}) => {
  const [openEditExperience, setOpenEditExperience] = useState(false)

  let years1=employeeDetails?.totalExp?.years;
  let months1=employeeDetails?.totalExp?.months;
  // if(employeeDetails?.totalExp?.years>years)
  // {
  //   years1=employeeDetails?.totalExp?.years;
  //   months1=employeeDetails?.totalExp?.months;
  // }
  // else
  // {
  //   years1=years;
  //   months1=months;
  // }

  return (
   <div className="row m-2">

    <div className="col-xl-4 col-lg-6 col-md-8 col-sm-12" style={{backgroundColor:'white', height:'auto', borderRadius:10,display:'flex', justifyContent:'center', alignItems:'center' }}>
    <div className="detailsBox" style={{ display:'flex', justifyContent:'center', alignItems:'center',width:'100%' , margin:'15px 0px', flexDirection:'row'}}>
    
       <div style={{flexDirection:'column'}}>
        <div><h4>EMPLOYEE ID  </h4></div>
        <div><h4>NAME   </h4></div>
        <div >
          <h4>TOTAL EXP   </h4>
     
          </div>
        <div><h4>LAST UPDATED   </h4></div>
       </div>
       <div style={{flexDirection:'column',marginLeft:"10px"}}>
        <div><h4>  : </h4></div>
        <div><h4>  :  </h4></div>
        <div >
          <h4> :  </h4>
     
          </div>
        <div><h4>  :  </h4></div>
       </div>
       <div  style={{flexDirection:'column', marginLeft:'20px'}}>
       <div><h4>{employeeDetails.employeeId ?employeeDetails.employeeId:"Not added"} </h4></div>
        <div><h4>{employeeDetails.name}</h4></div>
        <div className='d-flex justify-content-around '>
          <h4>{years1?years1:0} Years {months1?months1:0} Months </h4>
   
                <i className="fas fa-edit mx-3" style={{ color: "#32325d", cursor:'pointer' }} onClick={()=> setOpenEditExperience(true)}></i>
          </div>
        <div><h4>{lastUpdated==="NaN-NaN-NaN"?"Not data added":lastUpdated}</h4></div>

       </div>
   
    </div>
    </div>  


    {openEditExperience && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal
 
            show={openEditExperience}
            onHide={() => setOpenEditExperience(false)}
            dialogClassName="my-modal"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header className="m-2 p-2" closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
               EDIT EXPERIENCE
              </Modal.Title>
            </Modal.Header>
                <EditExperience setRefresh= {setRefresh} employeeDetails = {employeeDetails} userId = {employeeDetails?._id} setOpenEditExperience= {setOpenEditExperience}/>
          </Modal>
        </div>
      )}

   </div>
  )
}

export default EmployeeCard