import React, { useState } from 'react'
import { Button, Form , Row} from "react-bootstrap";
import instance from '../utils/axios';
import Swal from "sweetalert2";
 function AddEmployee({openAddEmployee,setOpenAddEmployee, refresh, setRefresh}) {
    const [newEmployee, setNewEmployee] = useState({
        empId:'',
        empName:'',
        empEmail:'',
        empExpYear:0,
        empExpMonth:0,
        role:'employee'
      })
      const [isFormInvalid, setIsFormInvalid] = useState(false)

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success m-1",
            cancelButton: "btn btn-danger m-1",
            // actions :"btn-spacing-sweet-alert mr-5"
        },
        buttonsStyling: true,
    });
       const handleAddEmployee = async()=>{
        const valid = validateForm()
        try {
        if(valid){
            // Add the code to add the employee
            const input = {
              employeeId : newEmployee.empId,
              name:newEmployee.empName,
              email:newEmployee.empEmail,
              totalExp:{
                years:newEmployee.empExpYear,
                months:newEmployee.empExpMonth
              },
              role:newEmployee.role
            }

            // console.log("This is input", input)
            const addNewEmployee = await instance.post('/employee/addEmployee',input)
            
            if(addNewEmployee?.data?.status === 'success')
            {
              // console.log("This is result of add new employee", addNewEmployee)
              // alert('Yes')
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'success',
                text: "Employee Added Successfully",
                showConfirmButton: false,
                timer: 3000
              })
              // add sweet alert here
              setOpenAddEmployee(false)
              setRefresh(!refresh)
            
            }else if(addNewEmployee?.data?.status === "updated" ){
              // alert(addNewEmployee?.data?.message)
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'success',
                text: "Employee Already exists and details updated",
                showConfirmButton: false,
                timer: 3000
              })
              setOpenAddEmployee(false);
              setRefresh(!refresh);
            }   else if(addNewEmployee?.data?.status === "idExists" ){
              // alert(addNewEmployee?.data?.message)
              // setOpenAddEmployee(false);
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: 'Error',
                text: "Employee with this id already exists",
                showConfirmButton: false,
                customClass: {
                  container: 'swal2-container', // Apply a custom class
                },
                timer: 1500
              })
              setRefresh(!refresh);
            }  
            else{
    //  alert('No')
       Swal.fire({
      position: 'middle',
      icon: 'error',
      title: 'Error',
      text: "Could not add employee",
      showConfirmButton: false,
      timer: 3000
    })
            }
            
        }else{
      
      setIsFormInvalid(true);
  //   }
      // console.log("This is input", input);
        }


        
      } catch (error) {
        console.log("This is error", error)
        if(error.status === 500){
          alert(error.message)
        }else{
          alert("Error occured")
        }
      }
     
    }
  // else {
  //     setIsFormInvalid(true);
  //   }
    // console.log("This is new employee details", newEmployee)
  
  const validateForm = () => {
    const emailValid = validEmail();
    // console.log("This is email vaid",emailValid)
    return (
      newEmployee?.empId.trim() !== "" &&
      newEmployee?.empName.trim() !== "" &&
      newEmployee?.empExpMonth >= 0 &&
      newEmployee?.empExpMonth <= 12 &&
      newEmployee.empExpYear >= 0 &&
      newEmployee.empExpYear <= 25 &&
      emailValid === true
    );
  };
  const validEmail = () => {
    const email = newEmployee?.empEmail;
    // console.log("This is eamil", email)
    // const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const regex = /^[a-zA-Z0-9._%+-]+@seqato\.com$/;
    return regex.test(email);
  };
  return (
    <div className="container">
      <div className=" m-3">
        {/* Employee id */}
        <Form.Group className="mb-2">
          <Form.Label>Employee ID : </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the  ID"
            //   value={firstName}
            //   disabled={true}
            style={{ backgroundColor: "#f5f5f5" }}
            isInvalid={isFormInvalid && newEmployee?.empId.trim() === ""}
            onChange={(e) => {
              e.preventDefault();
              setNewEmployee({
                empId: e?.target?.value,
                empName: newEmployee.empName,
                empEmail: newEmployee.empEmail,
                empExpMonth: newEmployee.empExpMonth,
                empExpYear: newEmployee.empExpYear,
                role: newEmployee.role,
              });
            }}
            // isInvalid={isFormInvalid && kitchenName.trim() === ""}
          />
          {isFormInvalid && newEmployee.empId.trim() === "" && (
            <Form.Control.Feedback type="invalid">
              Enter a valid ID
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {/* Name */}
        <Form.Group className="mb-2">
          <Form.Label>Employee Name : </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the  name"
            //   value={firstName}
            //   disabled={true}
            isInvalid={isFormInvalid && newEmployee?.empName.trim() === ""}
            style={{ backgroundColor: "#f5f5f5" }}
            onChange={(e) => {
              e.preventDefault();
              setNewEmployee({
                empId: newEmployee.empId,
                empName: e.target.value,
                empEmail: newEmployee.empEmail,
                empExpMonth: newEmployee.empExpMonth,
                empExpYear: newEmployee.empExpYear,
                role: newEmployee.role,
              });
            }}
            // isInvalid={isFormInvalid && kitchenName.trim() === ""}
          />
          {isFormInvalid && newEmployee.empName.trim() === "" && (
            <Form.Control.Feedback type="invalid">
              Enter a valid name
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {/* Email */}
        <Form.Group className="mb-2">
          <Form.Label>Email : </Form.Label>
          <Form.Control
            type="email"
            style={{ backgroundColor: "#f5f5f5" }}
            placeholder="Enter the  email"
            isInvalid={isFormInvalid && validEmail() === false}
            //   value={firstName}
            //   disabled={true}
            onChange={(e) => {
              e.preventDefault();
              setNewEmployee({
                empId: newEmployee.empId,
                empName: newEmployee.empName,
                empEmail: e.target.value,
                empExpMonth: newEmployee.empExpMonth,
                empExpYear: newEmployee.empExpYear,
                role: newEmployee.role,
              });
            }}
            // isInvalid={isFormInvalid && kitchenName.trim() === ""}
          />
          {isFormInvalid && validEmail() === false && (
            <Form.Control.Feedback type="invalid">
              Enter a valid email
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {/* Total experience */}
        <Form.Group className="mb-2">
          <Form.Label>Total Experience : </Form.Label>
          <div
            className=""
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Form.Control
              type="number"
              placeholder="Enter the total years"
              //   value={firstName}
              //   disabled={true}
              isInvalid={isFormInvalid && newEmployee?.empExpYear < 0}
              style={{ backgroundColor: "#f5f5f5", width: "47%" }}
              onChange={(e) => {
                e.preventDefault();
                setNewEmployee({
                  empId: newEmployee.empId,
                  empName: newEmployee.empName,
                  empEmail: newEmployee.empEmail,
                  empExpYear: e?.target?.value,
                  empExpMonth: newEmployee.empExpMonth,
                  role: newEmployee.role,
                });
              }}
              // isInvalid={isFormInvalid && kitchenName.trim() === ""}
            />

            <Form.Control
              type="number"
              placeholder="Enter the total months"
              //   value={firstName}
              //   disabled={true}
              isInvalid={isFormInvalid && newEmployee?.empExpMonth < 0}
              style={{ backgroundColor: "#f5f5f5", width: "47%" }}
              onChange={(e) => {
                e.preventDefault();
                setNewEmployee({
                  empId: newEmployee.empId,
                  empName: newEmployee.empName,
                  empEmail: newEmployee.empEmail,
                  empExpYear: newEmployee.empExpYear,
                  empExpMonth: e?.target?.value,
                  role: newEmployee.role,
                });
              }}
              // isInvalid={isFormInvalid && kitchenName.trim() === ""}
            />
          </div>
          <div>
            {isFormInvalid && newEmployee.empExpYear < 0 && (
              <Form.Control.Feedback type="invalid">
                Enter a valid no of years
              </Form.Control.Feedback>
            )}
            {isFormInvalid && newEmployee.empExpMonth < 0 && (
              <Form.Control.Feedback type="invalid">
                Enter a valid no of months
              </Form.Control.Feedback>
            )}
          </div>
        </Form.Group>
        <div className="d-flex ">
          <div className="mx-3 ">
            {newEmployee.empExpYear > 25 ? <p className="text-red" style={{ fontSize: "12px" }}>
              *Year cannot be greater than 25
            </p>: <p></p>}
          </div>
          <div className="ms-5">
          {newEmployee.empExpMonth > 11 ?   <p className="text-red" style={{ fontSize: "12px" }}>
              *Month cannot be greater than 11
            </p> : <p></p>}
          </div>
        </div>

        {/* role */}
        <Form.Group className="mb-2">
          <Form.Label>Select Role :</Form.Label>

          <Form.Select
            size="lg"
            onChange={(e) => {
              e.preventDefault();
              setNewEmployee({
                empId: newEmployee.empId,
                empName: newEmployee.empName,
                empEmail: newEmployee.empEmail,
                empExpYear: newEmployee.empExpYear,
                empExpMonth: newEmployee.empExpMonth,
                role: e.target.value,
              });
            }}
            // isInvalid={isFormInvalid && orderTypeId.trim() === ""}
            style={{
              backgroundColor: "#f5f5f5",
              width: "100%",
              height: "40px",
              borderRadius: 4,
              borderColor: "#cad1d7",
            }}
          >
            <option value="employee" name="employee" key={1}>
              Employee
            </option>
            <option value="admin" name="admin" key={1}>
              Admin
            </option>
          </Form.Select>
        </Form.Group>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button className="bg-success mt-2" onClick={handleAddEmployee}>
            Add Employee
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddEmployee;
