import React from 'react'

const EmployeeTableRow = ({skillSetArray}) => {
  return (
    <div className="table-responsive">
    <table className="table align-items-center table-flush">
        <thead className="thead-light">
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Years</th>
                <th scope="col">Months</th>
                <th scope="col">Last Used</th> 
            </tr>
        </thead>
        <tbody>
            {
                skillSetArray.map((item, index) => {
                    return ( <tr key={index}>
                    <th scope="row">
                        <div className="media align-items-center">
                          <div className="media-body">
                            <span className="mb-0 text-sm">
                              {item.skillName}
                            </span>
                          </div>
                        </div> 
                        </th>
                    <td>
                       {item.years }
                    </td>
                    <td>
                    {item.months }
                    </td>
                    <td>
                      { item.lastUsed }
                    </td>
                   
                  </tr>
                    )
                })
            }

        </tbody>
    </table>
</div>
   
  )
}

export default EmployeeTableRow