import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import PopupCoursesTable from './PopupCoursesTable';
import splitWord from '../utils/splitWord';

const ListCourseTable = ({item}) => {
    const [viewCourseDetails, setViewCourseDetails] = useState(false);
    const handleViewCourseSkills = async (e) => {
        e.preventDefault();
        // setEmployeeSkillList(item?.skills)
        setViewCourseDetails(true);
    }
    return (
        <>
        <tr>
            <th >
               {item?.basicDetails?.employeeId}
            </th>
            <th >
            {item?.basicDetails?.name}
            </th>
            <th >
            {item?.courses?.length > 0 ? splitWord((item?.courses.map((item, index) => index < 4 ? (item?.courseName + ", ") : "")).join('')) : 'Not added'}
            </th>
            <th>
                <a
                    className="btn btn-sm btn-icon-only text-light"
                    href="#"
                    role="button"
                    onClick={handleViewCourseSkills}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i className="fas fa-eye" style={{ color: "#000000" }}></i>
                </a>
            </th>
        </tr>
        <Modal centered show={viewCourseDetails} onHide={() => setViewCourseDetails(false)} dialogClassName="my-modal" aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header className="p-2 ml-2" closeButton>
            <Modal.Title>
                Employee Name: {item?.basicDetails?.name}
            </Modal.Title>
            
        </Modal.Header>
   
        <div className="container List" style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <PopupCoursesTable coursesArray={item?.courses} />
        </div>

    </Modal>
    </>
    )
}

export default ListCourseTable