import React, { useState } from 'react'
import instance from '../utils/axios';
import Swal from 'sweetalert2';

const AddNewSkill = ({ setRefresh, setCurrentPage }) => {
  const [data, setData] = useState({
    skillName: "",
    status: "active"
  })
  const [validForm, setValidForm] = useState(true);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleValid = () => {

    return data.skillName !== "";
  };
  const handleSubmit = async () => {
    const valid = handleValid();
    setValidForm(valid);
    if (valid) {
      try {
        const output = await instance.post("/skillSet/createSkillSet", data);
        if (output) {
          setRefresh((prev) => !prev);
          // setUpdatedSkills((prev) => !prev);
          // console.log(output?.data ,"ConsoleState")
          setData({
            skillName: "",
            status: "active"
          });
          if (output?.data?.status === "skillExists") {
            // console.log(" inside skill exists ConsoleState")
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Error',
              text: output?.data?.message || "Skill exists",
              showConfirmButton: false,
              timer: 2000
            })
          }
          if (output?.data?.status === "success") {
            setCurrentPage(1);
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'success',
              text: output?.data?.message || "Skill Added Successfully",
              showConfirmButton: false,
              timer: 2000
            })
          }
          // console.log("this is output", output)
        }
      } catch (err) {
        // console.log(err.response.data.status,"this is error")

        Swal.fire({
          position: 'middle',
          icon: 'error',
          title: 'Error',
          text: err?.response?.data?.message || "Skill exists",
          showConfirmButton: false,
          timer: 2000
        })

      }

      // alert(JSON.stringify(data))
    }
    // console.log(data)
  }
  return (
    <>
      <tr>
        <td>
          <input
            value={data.skillName}
            type="text"
            name="skillName"
            onChange={handleChange}
            className="input-field"
            placeholder="Skill name"
          // min="0"
          // max="25"
          />
        </td>
        {/* Months of experience */}
        <td>
          <select className="input-field" name='status'
            value={data.status} onChange={handleChange}
          >
            <option value="active">Active</option>
            <option value="inActive">Inactive</option>
          </select>
        </td>

        {/* Last used date */}
        {/* <td>
    <input
      // value={data.lastUsed}
      type="text"
      name="lastUsed"
      // onChange={handleChange}
      className="input-field"
      placeholder="Last Used Date"
    />
  </td> */}

        <td>
          <button className="blue-button"
            onClick={handleSubmit}
          >
            ADD
          </button>
        </td>
      </tr>
      <tr>
        {validForm === false && data?.skillName.trim() === "" ? (
          <td className="text-red">* Skill Name cannot be empty</td>
        ) : (
          <td></td>
        )}</tr>
    </>
  )
}

export default AddNewSkill