import './App.css';

import { useEffect, useState } from 'react';
import instance from './utils/axios';
import Router from './Router';
import Swal from "sweetalert2";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Cookies from "js-cookie";
import axios from 'axios';
function App() {
  const [user, setUser] = useState(null);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success m-1",
      cancelButton: "btn btn-danger m-1",
      // actions :"btn-spacing-sweet-alert mr-5"
    },
    buttonsStyling: true,
  });

  const getUser = async () => {
    try {
      // const url = `${process.env.REACT_APP_API_URL}/auth/login/success`;
      // let token = Cookies.get("token");
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/user/login/success`,
        { withCredentials: true });
      // console.log("data", data.employee[0]);
      // alert(data.employee[0].email)
      const data = response?.data
      setUser(data?.employee);
      localStorage.setItem('userData', JSON.stringify(data?.employee));
      // const details = localStorage.getItem('userData');
      // setUser(details)
      // console.log(details);
    } catch (err) {
      // console.log(err?.response?.data?.message,"final");

      const error = err?.response?.data?.message
      if (error !== "LoggedOut")
        Swal.fire({
          position: 'middle',
          icon: 'error',
          title: error,
          text: "You won't be able to login, Please contact HR team",
          showConfirmButton: true,
          timer: 5000
        })
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  // console.log("process.env ", process.env.REACT_APP_CLIENTID);

  // console.log(user);
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENTID}  >
      <Router user={user} />
    </GoogleOAuthProvider>
  );
}

export default App;
