import React, { useEffect, useState } from 'react'
import instance from '../utils/axios';
import { Link, useNavigate } from 'react-router-dom'
import RadialMenu from './RadialMenu';

const Navbar = ({ name, role, picture, userData, screen }) => {
    // alert(role);
    const navigate = useNavigate();
    const handleLogout = async () => {
        // Clear "userData" from localStorage
        localStorage.removeItem('userData');

        // Redirect the user to the logout URL
        window.location.href = `${process.env.REACT_APP_BACKEND}/user/logout`;
        // const res = await instance.get('/user/logout');
    };

    const [user, setUser] = useState(role === "admin" ? "Admin" : "User");

    const handleToggle = () => {
        setUser(user === "User" ? "Admin" : "User");
        user === "User" ? navigate("/admin") : navigate("/employee")
    };

    useEffect(() => {
        userData && setUser(userData)
        // console.log("User from navbar",user)
    }, [])

    return (
        <nav
            className="navbar navbar-top navbar-expand-md navbar-dark"
            id="navbar-main"
        >
            <RadialMenu role={role} user={user} screen={screen} />
            <div className="container-fluid">
                {/* Brand */}
                <Link
                    to='#'
                    className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"

                >
                    <img src="/images/seqato.png" alt="..." width={150} height={50} />
                </Link>

                <ul className="navbar-nav align-items-center d-none d-md-flex">
                    <li className="nav-item dropdown" style={{ display: 'flex', flexDirection: 'row' }}>
                        {
                            role == "admin" && <div>
                                <label className="switch">
                                    <input type="checkbox" checked={user === "Admin"} onChange={handleToggle} />
                                    <span className="slider">
                                        <p id="sliderValue" style={user === "User" ? { marginLeft: "22px", fontSize: "16px", fontWeight: "bold" } : { marginRight: "28px", fontSize: "16px", fontWeight: "bold" }}>{user}</p>
                                    </span>
                                </label>
                            </div>
                        }
                        <a
                            className="nav-link pr-0"
                            href="#"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >

                            <div className="media align-items-center"  >
                                <span className="avatar avatar-sm rounded-circle">
                                    <img
                                        alt="Image placeholder"
                                        src={picture ? picture : "/avatar.svg"}
                                    />
                                </span>
                                <div className="media-body ml-2 d-none d-lg-block">
                                    <span className="mb-0 text-sm  font-weight-bold" >
                                        {name}
                                    </span>
                                </div>

                            </div>

                        </a>
                        <a
                            className="nav-link pr-0"
                            // href="http://localhost:5000/user/logout"
                            onClick={handleLogout}
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ marginTop: '6px', cursor: 'pointer' }}
                        >

                            Logout

                        </a>
                    </li>
                </ul>

            </div>
        </nav>
    )
}

export default Navbar