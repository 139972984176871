function getFullMonthName(monthNumber) {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
        return monthNames[monthNumber - 1];
    } 

export default getFullMonthName
  
//   const fullMonthName = monthNames[monthNumber - 1];