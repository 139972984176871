import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';




const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  // timeout: 25000,
});

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('token');
    const refreshToken = Cookies.get('refreshToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (refreshToken) {
      config.headers['x-refresh-token'] = refreshToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


instance.interceptors.response.use(
  (response) => {
    // Do something with the successful response
    return response;
  },
  async (error) => {
    // console.log(error?.response, "from auth middleware")

    const originalRequest = error.config;
    // Step 3: Token Refresh Logic
    //   alert("hai1")
    if (error.response.status === 401) {
      // originalRequest._retry = false;
      // const response = await axios.get(`${process.env.REACT_APP_BACKEND}/user/logout`)
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: `btn btn blue-button`,
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      });

      Swal.fire({
        title: "Session expired, Please login",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        },
        allowOutsideClick: false, // This will prevent closing on clicking outside
        confirmButtonText: "OK", // Adding a confirmation button
        confirmButtonColor: "#00A4E3", // Change this to your desired color
      }).then((result) => {
        if (result.isConfirmed) {
          // Redirect to the specified URL when OK is clicked
          window.location.href = `${process.env.REACT_APP_BACKEND}/user/logout`;
        }
      });
      // console.log("this is response");
      // window.location.href = `${process.env.REACT_APP_BACKEND}/user/logout`;
    }
    // window.location.href('/')
    // logout('/')
    //console.log(  "out of the middleware")

    return Promise.reject(error);
  }
);





export default instance; 