import React, { useEffect, useState } from 'react'
import instance from '../utils/axios'
import Swal from "sweetalert2";
import reverseDate from '../utils/reverseDate';
import { Autocomplete, TextField } from '@mui/material';

const TableRow = ({ item, isEditing, onToggleEdit, setUpdatedSkills, userId,setRefresh }) => {
  const today = new Date()
  const [data, setData] = React.useState({
    id: item._id,
    skillName: item.skillName,
    years: item.years,
    months: item.months,
    lastUsed: item.lastUsed,
    userId: userId
  })
  const [validForm, setValidForm] = useState(true);
  const [skills, setSkills] = React.useState([]);
  useEffect(() => {
    (async () => {
      try {
        const response = await instance.get("/skillSet/getDistinctSkills");
        let data = response.data.data;
        // console.log(data)
        setSkills(data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  useEffect(() => {
    setData({
      id: item._id,
      skillName: item.skillName,
      years: item.years,
      months: item.months,
      lastUsed: item.lastUsed,
      userId: userId
    })
  }, [item])
  const handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target.value)
      setData({
        ...data,
        [e.target.name]: e.target.value
      })

    
    setValidForm(true);
  }
  const handleEditClick = () => {
    setRefresh(prev=>!prev)
    onToggleEdit(item._id); // Toggle editing state for the current row
  };
  const handleDelete = async (e) => {
    e.preventDefault();

    // const deleted = await instance.delete(`/employee/deleteSkill/${item._id}`);
    // if (deleted) {
    //   console.log('delete', data._id)
    //   onToggleEdit(null);
    //   setUpdatedSkills((prev) => !prev);
    // }


    ///////////Delete Popup////////////

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: `btn btn blue-button`,
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });

    const showDeleteConfirmation = async () => {
      const result = await swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#ffffff",
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true
      });

      if (result.isConfirmed) {
        try {
          // You can place your handleDelete code here
          const deleted = await instance.delete(`/employee/deleteSkill/${item._id}`);
          if (deleted) {
            setUpdatedSkills((prev) => !prev);
            await swalWithBootstrapButtons.fire(
              'Deleted!',
              'Your skill has been deleted.',
              'success'
            );
          }
        } catch (error) {
          await swalWithBootstrapButtons.fire(
            'Error',
            `Failed to delete: ${error.message}`,
            'error'
          );
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        await swalWithBootstrapButtons.fire(
          'Cancelled',
          'Your skill is safe :)',
          'error'
        );
      }
    };

    // Call the async function to show the confirmation dialog
    showDeleteConfirmation();




  }
  const handleValid = () => {
    const currentDate = new Date();
    const lastUsed = new Date(data.lastUsed);
    const months = data?.months === ""|| data?.months === null ? 0 : parseInt(data?.months);
    const years = data?.years === ""|| data?.years === null ? 0 : parseInt(data?.years);
    // console.log(data.skillName, years, months, lastUsed, currentDate);
    // console.log((years <= 25),data?.years)
    return data.skillName !== null && (years <= 25) && (months <= 11) && lastUsed <= currentDate&&(!(years === 0 && months === 0));
  };
  const editSubmit = async (e) => {
    e.preventDefault()
    //  console.log(JSON.stringify(data));

    try {
      const valid = handleValid();
      // alert(valid)
      setValidForm(valid);
      if (valid === true) {
        if (skills.includes(data?.skillName)){
          const resp = await instance.put('/employee/editSkill', data)
          if (resp?.data?.status === "success") {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'success',
              text: "Skill editted successfully",
              showConfirmButton: false,
              timer: 3000
            })
            onToggleEdit(null);
            setUpdatedSkills((prev) => !prev);
          } else if (resp?.data?.status === "skillExists") {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Error',
              text: resp?.data?.message || "Skill exists",
              showConfirmButton: false,
              timer: 2000
            })
            onToggleEdit(null);
            setUpdatedSkills((prev) => !prev);
          }
        }else{
          setValidForm(false);
          // Swal.fire({
          //   position: 'middle',
          //   icon: 'error',
          //   title: 'Error',
          //   text: "Please select skill from dropdown",
          //   showConfirmButton: false,
          //   timer: 2000
          // })
          // onToggleEdit(null);
          // setUpdatedSkills((prev) => !prev);
        }
       
      } else {
        // alert("Please enter a valid items");
        // setValidForm(true);
        // setData({
        //   id: item._id,
        //   skillName: item.skillName,
        //   years: item.years,
        //   months: item.months,
        //   lastUsed: item.lastUsed,
        //   userId:userId
        // })
      }

    } catch (error) {
      // console.log(error)
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Error',
        text: "Internal Server Error",
        showConfirmButton: false,
        timer: 3000
      })
      onToggleEdit(null);
      setUpdatedSkills((prev) => !prev);
    }
  }


  // console.log("This is item ====>>>", item)
  return (
    <>
      <tr>
        {/* Skill Name */}

        <th scope="row">
          {!isEditing ?
            <div className="media align-items-center">
              <div className="media-body">
                <span className="mb-0 text-sm">
                  {data.skillName}
                </span>
              </div>
            </div> :
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={data.skillName}
              onChange={(event, newValue) => {
                // setValue(newValue);
                // alert(newValue)
                setData({
                  ...data,
                  skillName: newValue,
                });
                // setValidForm(true);
              }}
              onInputChange={(event, newInputValue) => {
              
                  // If a valid option is entered, update the state
                  setData({
                    ...data,
                    skillName: newInputValue,
                  });
                  // Reset any error state or perform other actions for validation success
                
              }}
              options={skills}
              isOptionEqualToValue={(option, value) => option === value}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} label="Skill Name" size="small" />}
            />
            // <input type="text" value={data.skillName} onChange={handleChange} name="skillName" className="input-field" placeholder="Skill Name" />

          }
        </th>

        {/* years */}
        <td>{
          !isEditing ? data.years==null?0:data.years :
            <input type="number" name="years" value={data.years} onChange={handleChange} className="input-field" placeholder="Years of Experience" min='0' />
        }
        </td>
        {/* months */}
        <td>
          {
            !isEditing ? data.months==null?0:data.months :
              <input type="number" name="months" value={data.months} onChange={handleChange} className="input-field" placeholder="Months of Experience" max="11" min="0" />
          }
        </td>
        {/* last used date */}
        <td>
          {
            !isEditing ? reverseDate(data.lastUsed) :
              <input type="date" name="lastUsed" value={(data.lastUsed).toString()} onChange={handleChange} className="input-field" placeholder="Last Used Date" />
          }
        </td>
        {/* edit and delete icons */}
        <td >

          {!isEditing ?
            <>
              <a
                className="btn btn-sm btn-icon-only text-light"
                href="#"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleEditClick();
                }}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-edit" style={{ color: "#000000" }}></i>
              </a>
              <a
                className="btn btn-sm btn-icon-only text-light"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={
                  handleDelete
                }
              >
                <i className="fa-solid fa-trash" style={{ color: "#000000" }}></i>
              </a>
            </>
            :
            <>
              <a
                className="btn btn-sm btn-icon-only text-light"
                href="#"
                role="button"
                onClick={editSubmit}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-check" style={{ color: "#000000" }}></i>

              </a>
              <a
                className="btn btn-sm btn-icon-only text-light"
                href="#"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  onToggleEdit(null);
                  setValidForm(true);
                  setData({
                    id: item._id,
                    skillName: item.skillName,
                    years: item.years,
                    months: item.months,
                    lastUsed: item.lastUsed,
                    userId: userId
                  })

                }}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-times" style={{ color: "#000000" }}></i>
              </a>
            </>
          }
        </td>
      </tr>
      {
        validForm === false && (

          <tr>
            {validForm === false && !skills.includes(data?.skillName)? (
              <td className="text-red">* Please select skill from dropdown</td>
            ) : (
              <td></td>
            )}

            {validForm === false && (parseInt(data?.years) > 25 || data.years === "") ? (
              <td className="text-red">* Enter a year less than 25</td>
            ) : (
              <td></td>
            )}
            {validForm === false && (parseInt(data?.months) > 11 || data.months === ""||(parseInt(data.years) === 0 && parseInt(data?.months) === 0)) ? (
              <td className="text-red">* Enter a valid month {(parseInt(data.years) === 0 && parseInt(data.months) === 0)&&" atleast 1"}
              {parseInt(data?.months) > 11&&" less than 12"}</td>
            ) : (
              <td></td>
            )}

            {validForm === false && (new Date(data?.lastUsed) > today || data.lastUsed === "") ? (
              <td className="text-red">* Enter a valid date</td>
            ) : (
              <td></td>
            )}

          </tr>
        )
      }
    </>
  )
}

export default TableRow