function splitWord(fullWord) {
    return fullWord.split(' ')
    .reduce((acc, word, index) => {
        if (index % 6 === 0 && index !== 0) {
            acc.push(<br key={index} />);
        }
        acc.push(<span key={index}>{word} </span>);
        return acc;
    }, [])
}

export default splitWord
