import React from 'react'
import getFullMonthName from '../utils/getFullMonthName'
import splitWord from '../utils/splitWord'

const PopupCoursesTable = ({ coursesArray }) => {

  return (
    <div className="table-responsive">
      <table className="table align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Course Name</th>
            <th scope="col">Course Date</th>
            <th scope="col">Expiry Date</th>
          </tr>
        </thead>
        <tbody>
          {
            coursesArray.map((item, index) => {
              return (<tr key={index}>
                <th scope="row">
                  <div className="media align-items-center">
                    <div className="media-body">
                      <span className="mb-0 text-sm">
                        {splitWord(item.courseName)}
                      </span>
                    </div>
                  </div>
                </th>
                <td>
                  {getFullMonthName(item.courseDate.month) + " " + item.courseDate.year}
                </td>
                <td>
                  {getFullMonthName(item.expDate.month) + " " + item.expDate.year}
                </td>


              </tr>
              )
            })
          }

        </tbody>
      </table>
    </div>

  )
}

export default PopupCoursesTable