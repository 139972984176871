import React, { useEffect, useState } from "react";
import getFullMonthName from "../utils/getFullMonthName";
import splitWord from "../utils/splitWord";
import instance from "../utils/axios";
import Swal from "sweetalert2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Capitalize from "../utils/Capitalize";
function CourseTableRow({
  item,
  onToggleEdit,
  isEditing,
  userId,
  refresh,
  setRefresh,
}) {

  const [data, setData] = useState({
    userId: userId,
    courseName:"",
    courseDate: {
      month: null,
      year:null,
    },
    expDate: {
      month:null,
      year:null,
    },
  });

  const [dates, setDates] = useState({
    courseDate:"",
    expDate:""
  })

  const [validForm, setValidForm] = useState(true);

  const [error, setError] = useState({
    courseName: "",
    courseDate: "",
    expDate: "",
  })
  const handleEditClick = () => {
    setValidForm(true)
    onToggleEdit(item._id);
    console.log("This is item for edit", item)
    setData({
      userId: userId,
      courseName: item?.courseName ,
      courseDate: {
        month: item?.courseDate?.month ,
        year:  item?.courseDate?.year,
      },
      expDate: {
        month: item?.expDate?.month,
        year:  item?.expDate?.year,
      },
    });

    setDates({
      courseDate:`${item?.courseDate?.year}-${item?.courseDate?.month}-1`,
      expDate:`${item?.expDate?.year}-${item?.expDate?.month}-1`,
    })
    
  };

  const handleDelete = async (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deletedCourse = await instance.delete(
            `/course/deleteCourse/${item._id}`
          );
          console.log("This is deeleted course", deletedCourse?.data?.status);
          if (deletedCourse?.data?.status === "success") {
            Swal.fire({
              position: "middle",
              icon: "success",
              title: "Success",
              text: "Course deleted successfully",
              showConfirmButton: false,
              timer: 2000,
            });
            setRefresh(!refresh);
          } else {
            Swal.fire({
              position: "middle",
              icon: "error",
              title: "Error",
              text: "Couldn't delete course",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } catch (error) {
          console.log("Thie error", error);
          Swal.fire({
            position: "middle",
            icon: "error",
            title: "Error",
            text: "Error deleting course",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const handleValidation = () => {
    // Expiry date should be greater than or equal to Course Date
    let validExp = true;
    if (data?.expDate?.year !== null && data?.courseDate?.year !== null) {
      if (data?.expDate?.year < data?.courseDate?.year) {
        validExp = false;
      } else if (data?.expDate?.year === data?.courseDate?.year) {
        if (data?.expDate?.month < data?.courseDate?.month) {
          validExp = false;
        }
      }
    }

    return (
      data?.userId !== "" &&
      data?.courseName !== "" &&
      data?.courseDate?.year !== null &&
      data?.courseDate?.month !== null &&
      validExp === true
    );
  };

  useEffect(() => {

  const handleError = ()=>{
    if(data?.courseName.trim() === ""){
      setError((prev)=>({...prev, courseName: "Course name is required"}))
    }else{
      setError((prev)=>({...prev, courseName: ""}))
    }
    if(data?.courseDate?.year === null){
      setError((prev)=>({...prev, courseDate: "Course date is required"}))
    }else{
      setError((prev)=>({...prev, courseDate: ""}))
    }
    if(data?.expDate?.year !== null && data?.courseDate?.year !== null){
      if(data?.expDate?.year < data?.courseDate?.year){
        setError((prev)=>({...prev, expDate: "Expiry date should be greater than or equal to course date"}))
      }else if(data?.expDate?.year === data?.courseDate?.year){
        if(data?.expDate?.month < data?.courseDate?.month){
          setError((prev)=>({...prev, expDate: "Expiry date should be greater than or equal to course date"}))
        }else{
          setError((prev)=>({...prev, expDate: ""}))
        }
      }else{
        setError((prev)=>({...prev, expDate: ""}))
      }
    
    }else{
      setError((prev)=>({...prev, expDate: ""}))
    }
  }
  console.log("This is error message object", error)
  handleError()
  }, [data])

  const editSubmit = async (e) => {
   console.log("Edit submit data", data)
   try {
     const valid = handleValidation();
     setValidForm(valid)
     if(valid){
       const submitEditResponse = await instance.patch(`/course/editCourse/${item._id}`, data)
       if(submitEditResponse?.data?.status === "success"){
         Swal.fire({
           position: "middle",
           icon: "success",
           title: "Success",
           text: submitEditResponse?.data?.message || "Course edited successfully",
           showConfirmButton: false,
           timer: 2000,
         });
         setRefresh(!refresh)
         onToggleEdit(null)
       }else{
         Swal.fire({
           position: "middle",
           icon: "error",
           title: "Error",
           text: submitEditResponse?.data?.message || "Couldn't edit course",
           showConfirmButton: false,
           timer: 2000,
         });
       }
     }
   } catch (error) {
    console.log(error)
    Swal.fire({
      position: "middle",
      icon: "error",
      title: "Error",
      text: error?.message || "Error occured while editing course",
      showConfirmButton: false,
      timer: 2000,
    });
   }
  };
  return (
    <>
      <tr>
        <th scope="row">
          {/* Course Name */}
          {!isEditing ? (
            <div className="media align-items-center">
              <div className="media-body">
                <span className="mb-0 text-sm">
                  {splitWord(item?.courseName)}
                </span>
              </div>
            </div>
          ) : (
            <input
              type="text"
              value={data.courseName}
              onChange={(e)=>{
                setData({
                  userId: userId,
                  courseName: e?.target?.value,
                  courseDate: {
                    month: data?.courseDate?.month,
                    year: data?.courseDate?.year,
                  },
                  expDate: {
                    month: data?.expDate?.month,
                    year: data?.expDate?.year,
                  },
                })
              }}
              name="courseName"
              className="input-field"
              placeholder="Course Name"
            />
          )}
        </th>
        {/* Date */}
        <td>
          {!isEditing ? (
            <p>
              {" "}
              {`${getFullMonthName(item?.courseDate?.month)} ${
                item?.courseDate?.year
              }`}
            </p>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label={"Select Course Date"}
                  views={["month", "year"]}
                  disableFuture
                value={dayjs(`${item?.courseDate?.year}-${item?.courseDate?.month}-1`)}

                  slotProps={{
                    textField: { size: "small" },
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}

                   onChange={(e) => {
                     setData({
                       userId: userId,
                       courseName: data.courseName,
                       courseDate: {
                         month: e?.$M + 1 || null,
                         year: e?.$y || null,
                       },
                       expDate: {
                         month: data.expDate.month,
                         year: data.expDate.year,
                       },
                     });
                   }}
                />
              </DemoContainer>
            </LocalizationProvider>
          )}
        </td>
        {/* Expiry Date */}
        {!isEditing ? (
          <td>
           <p> {item?.expDate?.month
              ? `${getFullMonthName(item?.expDate?.month)} ${
                  item?.expDate?.year
                }`
              : "N/A"}</p>
          </td>
        ) : (
          <td>
            <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label={"Select Expiry Date"}
                views={["month", "year"]}
                // disableFuture
                slotProps={{
                  textField: { size: "small" },
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                value={item?.expDate?.year ? dayjs(`${item?.expDate?.year}-${item?.expDate?.month}-1`) : null}
                onChange={(e) => {
                  setData({
                    userId: userId,
                    courseName: data.courseName,
                    courseDate: {
                      month: data.courseDate.month,
                      year: data.courseDate.year,
                    },
                    expDate: {
                      month: e?.$M + 1 || null,
                      year: e?.$y || null,
                    },
                  });
                
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          </td>
        )}

        {/* Actions */}
        {!isEditing ? (
          <td className="my-4">
            <a
              className="btn btn-sm btn-icon-only text-light"
              href="#"
              role="button"
              onClick={(e) => {
                e.preventDefault();
                handleEditClick();
              }}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-edit" style={{ color: "#000000" }}></i>
            </a>
            <a
              className="btn btn-sm btn-icon-only text-light"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={handleDelete}
            >
              <i className="fa-solid fa-trash" style={{ color: "#000000" }}></i>
            </a>
          </td>
        ) : (
          <td>
            <a
              className="btn btn-sm btn-icon-only text-light"
              href="#"
              role="button"
              onClick={editSubmit}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-check" style={{ color: "#000000" }}></i>
            </a>
            <a
              className="btn btn-sm btn-icon-only text-light"
              href="#"
              role="button"
              onClick={(e) => {
                e.preventDefault();
                onToggleEdit(null);
                // setValidForm(true);
                // setData({
                //   id: item._id,
                //   skillName: item.skillName,
                //   years: item.years,
                //   months: item.months,
                //   lastUsed: item.lastUsed,
                //   userId: userId
                // })
              }}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-times" style={{ color: "#000000" }}></i>
            </a>
          </td>
        )}
      </tr>
      {
        isEditing && validForm === false &&(error.courseDate!=="" || error.courseName!=="" || error.expDate!=="")  ?(

      <tr>
      
        <td><p style={{ color: "red" , fontSize:12}}>{error.courseName}</p></td>
        <td><p style={{ color: "red" , fontSize:12}}>{error.courseDate}</p></td>
        <td><p style={{ color: "red" , fontSize:12}}>{error.expDate}</p></td>
        <td></td>
      </tr>
        ) : <></>
      }
    </>
  );
}

export default CourseTableRow;
