import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import AddNewSkill from '../components/AddNewSkill'
import AddSkillTable from '../components/AddSkillTable'
import { Pagination } from '@mui/material'
import instance from '../utils/axios'

const AddSkillScreen = ({ name,
  role,
  picture }) => {
  const [currentlyEditing, setCurrentlyEditing] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [searchList, setSearchList] = useState({
    skillName: "",
    // skillAdded false means skill is not added
  });
  const [skillData, setSkillData] = useState([ ]);
  useEffect(() => {
    const fetchAllEmployees = async () => {
      console.log(searchList,"searchList");
      try {
        const data = await instance.post("/skillSet/getAllSkillSet", {
          page: currentPage,
          pageSize: 10,
          search: searchList.skillName,
        });

        // Api response and search list is correct . Search issue is below
        if (data) {
          // console.log(data,"getSkillset");
          // Search issue is here while setting setAllEmployees() value updating on next refresh.. 
          // In admin table item is consoled even before the searchList is consoled
          // Need to fix that
          setSkillData(data?.data?.data);
          setTotalPages(data?.data?.pagination?.totalPages);
        }
        else {
          console.log("Employee list not found")
        }

      } catch (error) {
        console.log("Error in fetchAllEmployees", error);
      }
    };
    fetchAllEmployees();
  }, [refresh, currentPage, searchList]);
  const handleToggleEdit = (rowId) => {
    // console.log("This is row id", rowId)
    setCurrentlyEditing(rowId);
  };
  const handlePagination = (event, value) => {
    event.preventDefault();
    setCurrentPage(value);
    // setRefresh(!refresh);
    // alert(value)
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let value = e.target.value;
    let name = e.target.name;
    // console.log(value, name);
    setSearchList({
      ...searchList,
      [name]: value,
    });
    setCurrentPage(1);
  };
  return (
    <div className="main-content">
      <Navbar name={name} role={role} picture={picture} screen="Admin" userData="Admin" />
      <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8 ">
        {/* ------ Add Employee -------- */}

        {/* --------- Filter ------------- */}
        <div className="container">
          <div className="input-row">
            <div className="input-container">
              <label className="input-label" htmlFor="employeeId">
                Skills :
              </label>
              <input
                type="text"
                id="employeeId"
                className="input-field"
                style={{ width: "40%" }}
                placeholder="Search by Skills"
                name="skillName"
                onChange={handleSearch}
              />
            </div>

          </div>
        </div>
        {/* ...................................... */}
      </div>
      <div className="container-fluid mt--7">
        <div className="row">
          <div className="col">
            <div className="card shadow">
              <div className="card-header border-0">
                <h3 className="mb-0">Add Skill List</h3>
              </div>
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Skill Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>

                    {/* {skillData?.map((item, index) => {
                      return (
                        <AdminTableRow
                          key={index}
                          item={item}
                          isEditing={
                            currentlyEditing === item?.basicDetails?._id
                          }
                          onToggleEdit={handleToggleEdit}
                          currentPage
                        />
                      );
                    })} */}
                    {
                      skillData?.map((item, index) => {
                        return (<AddSkillTable
                          key={index}
                          item={item}
                          isEditing={
                            currentlyEditing === item?._id
                          }
                          onToggleEdit={handleToggleEdit}
                          setRefresh={setRefresh}
                        />)

                      })
                    }
                    <AddNewSkill setRefresh={setRefresh} setCurrentPage={setCurrentPage}/>
                  </tbody>
                </table>
              </div>
              <div className="card-footer py-4 d-flex justify-content-end">
                {/* <nav aria-label="...">
                  <ul className="pagination justify-content-end mb-0">
                    <li className="page-item disabled">
                      <a className="page-link" href="#" tabIndex={-1}>
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2 <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav> */}
                <Pagination
                  count={totalPages}
                  // color="primary"
                  className="pagination"
                  sx={{
                    '.MuiPaginationItem-page.Mui-selected': {
                      backgroundColor: '#00D4FF',
                      color: 'white',
                    },
                    '& .MuiPaginationItem-page:hover': {
                      backgroundColor: '#00D4FF',
                      color: 'white',
                    },
                  }}
                  page={currentPage}
                  onChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default AddSkillScreen