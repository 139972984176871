import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import instance from "../utils/axios";
import Swal from "sweetalert2";
import dayjs from "dayjs";
function AddCourse({ userId, setRefresh, refresh }) {
  // console.log("This is user id", userId);
  const [data, setData] = useState({
    userId: userId,
    courseName: "",
    courseDate: {
      month: null,
      year: null,
    },
    expDate: {
      month: null,
      year: null,
    },
  });

  const [expValid, setExpValid] = useState(true);
  const [validForm, setValidForm] = useState(true);
  useEffect(() => {
    setData({
      userId: userId,
      courseName: "",
      courseDate: {
        month: null,
        year: null,
      },
      expDate: {
        month: null,
        year: null,
      },
    });
  }, []);

  const handleValidation = () => {
    // Expiry date should be greater than or equal to Course Date
    let validExp = true;
    if (data?.expDate?.year !== null && data?.courseDate?.year !== null) {
      if (data?.expDate?.year < data?.courseDate?.year) {
        validExp = false;
        setExpValid(false);
      } else if (data?.expDate?.year === data?.courseDate?.year) {
        if (data?.expDate?.month < data?.courseDate?.month) {
          validExp = false;
          setExpValid(false);
        }
      }
    }

    return (
      data?.userId !== "" &&
      data?.courseName !== "" &&
      data?.courseDate?.year !== null &&
      data?.courseDate?.month !== null &&
      validExp === true
    );
  };

  const handleSubmit = async () => {
    console.log("This is final data => ", data);
    const valid = handleValidation();
    // alert(valid)
    if (valid) {
      try {
        setValidForm(true);
        const response = await instance.post("/course/createCourse", data);
        if (response?.data?.status === "success") {
          Swal.fire({
            position: "middle",
            icon: "success",
            title: "Success",
            text: response?.data?.message || "Course added successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          
          setData({
            userId: userId,
            courseName: "",
            courseDate: {
              month: null,
              year: null,
            },
            expDate: {
              month: null,
              year: null,
            },
          });
          setRefresh(!refresh);
        } else {
          Swal.fire({
            position: "middle",
            icon: "error",
            title: "Error",
            text: response?.data?.message || "Couldn't add course",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } catch (error) {
        Swal.fire({
          position: "middle",
          icon: "error",
          title: "Error",
          text: error?.message || "Error occured while adding course",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      setValidForm(false);
    }
  };
  return (
    <>
      <tr>
        {/* Course Name */}
        <th scope="row">
          <input
            type="text"
            value={data?.courseName}
            onChange={(e) => {
              setData({
                userId: userId,
                courseName: e?.target?.value,
                courseDate: {
                  month: data.courseDate.month,
                  year: data.courseDate.year,
                },
                expDate: {
                  month: data.expDate.month,
                  year: data.expDate.year,
                },
              });
            }}
            name="courseName"
            className="input-field course-input"
            placeholder="Course Name"
          />
        </th>
        {/* Course Date */}
        <td>
          <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label={"Select Course Date"}
                views={["month", "year"]}
                value={data?.courseDate?.month ? dayjs(`${data?.courseDate?.year}-${data?.courseDate?.month}-1`) : null }
                disableFuture
                slotProps={{
                  textField: { size: "small" },
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                onChange={(e) => {
                  setData({
                    userId: userId,
                    courseName: data.courseName,
                    courseDate: {
                      month: e?.$M + 1 || null,
                      year: e?.$y || null,
                    },
                    expDate: {
                      month: data.expDate.month,
                      year: data.expDate.year,
                    },
                  });
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </td>
        {/* Expiry Date */}
        <td>
          <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label={"Select Expiration Date"}
                views={["month", "year"]}
                // defaultValue={dayjs('2022-04-17')}
                value={data?.expDate?.month ? dayjs(`${data?.expDate?.year}-${data?.expDate?.month}-1`) : null }
              
                // disableFuture
                slotProps={{
                  textField: { size: "small" },
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                onChange={(e) => {
                  setData({
                    userId: userId,
                    courseName: data.courseName,
                    courseDate: {
                      month: data.courseDate.month,
                      year: data.courseDate.year,
                    },
                    expDate: {
                      month: e?.$M + 1 || null,
                      year: e?.$y || null,
                    },
                  });
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </td>
        <td>
          <button className="blue-button" onClick={handleSubmit}>
            Add Course
          </button>
        </td>
      </tr>
      {/* Validation Messages */}
      <tr>
        <td className="text-red ">
          {validForm === false && data?.courseName === "" && (
            <p style={{ fontSize: 13 }}>*Course Name is required</p>
          )}
        </td>
        <td className="text-red ">
          {validForm === false && data?.courseDate?.month === null && (
            <p style={{ fontSize: 13 }}>* Course Date is required</p>
          )}
        </td>
        <td className="text-red ">
          {validForm === false && expValid === false && (
            <p style={{ fontSize: 13 }}>
              *Expiry date should be greater than or equal to Course Date
            </p>
          )}
        </td>
      </tr>
    </>
  );
}

export default AddCourse;
