import React, { useEffect, useState } from 'react'
import instance from '../utils/axios';
import Swal from 'sweetalert2';

const AddSkillTable = ({ item, isEditing, onToggleEdit,setRefresh }) => {

    const [data, setData] = useState({
        id: item?._id,
        skillName: item?.skillName,
        status: item?.status
    })
    const [validForm, setValidForm] = useState(true);
    const [error, setError] = useState(false)
    // const [refresh1, setRefresh1] = useState(false)

    // Use effect added to solve the duplicate id pagination error message issue
    useEffect(() => {
        setError(false)
    }, [item])
    useEffect(() => {
        // alert(item?.skillName)
        setData({
            id: item?._id,
            skillName: item?.skillName,
            status: item?.status
        })
    }, [item])
    const handleChange = (e) => {
        e.preventDefault();
        // console.log(e.target.value)
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    const handleEditClick = () => {
        setData({
            id: item?._id,
            skillName: item?.skillName,
            status: item?.status
        })
        setRefresh(prev=>!prev)
        // setRefresh1(prev=>!prev)
        onToggleEdit(data?.id); // Toggle editing state for the current row
    };
    const handleValid = () => {
        return data?.skillName !== ""
    }
    const handleSubmitEdit = async (e) => {
        e.preventDefault();

        // const userId = item?._id
        const valid = handleValid()
        setValidForm(valid)
        // console.log(data, "this is data");
        if (valid) {
            try {
                const editData = await instance.patch(`/skillSet/editSkillSet/${data.id}`, data)
                // console.log(editData?.data?.status,"ConsoleState")
                if (editData?.data?.status === 'success') {
                    // Add sweet alert here
                    // alert('Success')
                    Swal.fire({
                        position: 'middle',
                        icon: 'success',
                        title: 'success',
                        text: "Skill Edited Successfully",
                        showConfirmButton: false,
                        timer: 3000
                    })

                    setRefresh(prev => !prev)
                } else {
                    // alert("Couldn't edit the employee")
                    Swal.fire({
                        position: 'middle',
                        icon: 'error',
                        title: 'Error',
                        text: "Skill Couldn't be Edited",
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
                onToggleEdit(null);
            } catch (err) {
                // console.log(err?.response?.data?.message, "this is error")
                setValidForm(false)
                setError(true)
                Swal.fire({
                    position: 'middle',
                    icon: 'error',
                    title: 'Error',
                    text: err?.response?.data?.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            }

        }
        else {
            setError(true)
        }
    }
    return (
        <>
            <tr>
                <th >
                    {/* {isEditing ?
                        <input type="text"
                            value={data.employeeId}
                            onChange={handleChange}
                            name="employeeId"
                            className="input-field"
                            placeholder="Employee Id" />
                        : data.employeeId} */}
                    {isEditing ?
                        <input type="text"
                            value={data.skillName}
                            onChange={handleChange}
                            name="skillName"
                            className="input-field"
                            placeholder="Skill Name" />
                        : data.skillName}
                </th>
                <th>
                    {isEditing ? <select className="input-field" name='status'
                        value={data.status} onChange={handleChange}
                    >
                        <option value="active">Active</option>
                        <option value="inActive">Inactive</option>
                    </select> :
                        <span className="badge badge-dot mr-4">
                            <i className={data.status === "active" ? "bg-success" : "bg-danger"}></i>
                            {data.status}
                        </span>
                    }
                </th>
                <th>
                    {isEditing ?
                        <>

                            <a
                                className="btn btn-sm btn-icon-only text-light"
                                href="#"
                                role="button"
                                onClick={handleSubmitEdit}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i class="fas fa-check" style={{ color: "#000000" }}></i>

                            </a>
                            <a
                                className="btn btn-sm btn-icon-only text-light"
                                href="#"
                                role="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setValidForm(true)
                                    setData({
                                        id: item?._id,
                                        skillName: item?.skillName,
                                        status: item?.status
                                    })
                                    onToggleEdit(null);
                                }}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i class="fas fa-times" style={{ color: "#000000" }}></i>
                            </a>
                        </>
                        :
                        <a
                            className="btn btn-sm btn-icon-only text-light"
                            href="#"
                            role="button"
                            onClick={(e) => {
                                e.preventDefault();
                                handleEditClick();

                            }}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i className="fas fa-edit" style={{ color: "#000000" }}></i>
                        </a>
                    }
                </th>
            </tr>
            <tr>

                {validForm === false && error && data.skillName.trim() === "" ? (
                    <>
                        <td className="text-red">* Skill name can't be empty</td>
                    </>
                ) : (
                    ""
                )}
                {/*              
               {validForm === false && data?.name === "" ? (
                   <>
                     <td></td>
                   <td className="text-red">* Name cannot be empty</td>
                   </>
               ) : (
                   ""
               )} */}
            </tr>
        </>
    )
}

export default AddSkillTable