import React, { useEffect, useState } from 'react'
import { Button, Form , Row} from "react-bootstrap";
import instance from '../utils/axios';
import Swal from "sweetalert2";
function EditExperience({userId, setOpenEditExperience, employeeDetails, setRefresh}) {
  const [experience , setExperience] = useState({
    years:employeeDetails?.totalExp?.years || "",
    months:employeeDetails?.totalExp?.months || ""
  })
 const [formInvalid, setFormInvalid] = useState(false)
  const handleChange = (e)=>{
    setExperience({
      ...experience,
      [e.target.name] : e.target.value
    })
  }

  const handleValidation = ()=>{
    return(
     (experience?.years !== ""  || experience?.months !== "" )&& experience?.years <=25 && experience?.years >=0  && experience?.months <=11 && experience?.months >=0 
    )
  }
useEffect(()=>{
// console.log("experience =>",experience)
},[experience])
  const handleSubmit = async ()=>{
    try {
    const valid = handleValidation()
    // console.log("This is valid", valid)
    if(valid){
      // console.log("experience = ",experience)
      const addExperience = await instance.put(`/employee/editExperience/${userId}`,experience)
      if(addExperience?.data?.status === "success"){
        setOpenEditExperience(false)
       
        Swal.fire({
          position: 'middle',
          icon: 'success',
          title: 'Success',
          text: addExperience?.data?.message || "Successfully updated the experience",
          showConfirmButton: false,
          timer: 2000
        })
        setRefresh((prev) => !prev);
      }else{
        setOpenEditExperience(false)
        Swal.fire({
          position: 'middle',
          icon: 'error',
          title: 'Error',
          text: addExperience?.data?.message || "Could'nt update the experience",
          showConfirmButton: false,
          timer: 2000
        })
        setRefresh((prev) => !prev);
      }
    }else{
      setFormInvalid(true)
    }
    } catch (error) {
      setOpenEditExperience(false)
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Error',
        text: "Internal server error",
        showConfirmButton: false,
        timer: 2000
      })
    }
  }
  return (
    <div className="container">
      <div className="m-3">
          <Form>
            <Form.Group className='mb-2'>
              <Form.Label>Enter total years :</Form.Label>
              <Form.Control type='number' placeholder='Enter the total years of experience'
                    style={{ backgroundColor: "#f5f5f5" }}
                    min={0}
                    max={25}
                onChange={handleChange}
                name='years'
                value={experience.years}
                
     
              />
                      {/* {formInvalid && experience.years === "" && (
            <Form.Control.Feedback type="invalid">
              Enter a value for years
            </Form.Control.Feedback>
          )} */}
           {formInvalid === false && ( experience.years > 25) && (
          <p className="text-red">* Enter a valid years</p>
          )}
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Label>Enter total months :</Form.Label>
              <Form.Control type='number' placeholder='Enter the total months of experience'
                    style={{ backgroundColor: "#f5f5f5" }}
                    min={0}
                    max={11}
                    name='months'
                    onInvalid={handleValidation}
                    onChange={handleChange}
                    value={experience.months}
              />
                               {formInvalid === false && (experience.months > 11) && (
          <p className="text-red">* Enter a valid months</p>
          )}
            </Form.Group>
            <div className="d-flex justify-content-center mt-4">
              <Button className='bg-primary' onClick={handleSubmit}>Submit</Button>
            </div>
            {formInvalid === true && (experience.months === "" && experience.years === "")&&(
          <p className="text-red">* Enter atleast 1 year or 1 month</p>
          )}
          </Form>
      </div>
    </div>
  )
}

export default EditExperience
