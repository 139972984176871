import React, { useEffect, useState } from "react";
// import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AdminScreen from "./screens/AdminScreen";
import ExmployeeScreen from "./screens/EmployeeScreen";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./screens/Login";
import CoursesScreen from "./screens/CoursesScreen";
import AddSkill from "./screens/AddSkillScreen";
import ListCourcesScreen from "./screens/ListCoursesScreen";
const Router = ({ user }) => {
  // console.log("role", user?.role);
  // alert(user?.role);
  function isEmptyObject(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            user !== null ? (
              user?.role === "employee" ? (
                <ExmployeeScreen
                  email={user?.email}
                  role={user?.role}
                  picture={user?.picture}
                />
              ) : (
                <AdminScreen
                  name={user?.name}
                  role={user?.role}
                  picture={user?.picture}
                />
              )
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/admin"
          element={
            user?.role === "admin" && (
              <AdminScreen
                name={user?.name}
                role={user?.role}
                picture={user?.picture}
              />
            )
          }
        />
        <Route
          path="/employee"
          element={
            user?.role === "admin" && (
              <ExmployeeScreen email={user?.email} picture={user?.picture} />
            )
          }
        />
        <Route
          path="/user"
          element={
            (user?.role === "employee" ||
            user?.role === "admin") && (
              <ExmployeeScreen email={user?.email} picture={user?.picture} />
            )
          }
        />
        <Route
          path="/skills"
          element={
            <AddSkill
              name={user?.name}
              role={user?.role}
              picture={user?.picture}
            />
          }
        />
        <Route
          path="/course"
          element={
            <CoursesScreen
              name={user?.name}
              email={user?.email}
              role={user?.role}
              picture={user?.picture}
            />
          }
        />
        <Route
          path="/listcourse"
          element={
            <ListCourcesScreen
              name={user?.name}
              email={user?.email}
              role={user?.role}
              picture={user?.picture}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
