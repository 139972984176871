import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import EmployeeCard from "../components/EmployeeCard";
import instance from "../utils/axios";
import formatDate from "../utils/formatDate";
import AddCourse from "../components/AddCourse";
import CourseTableRow from "../components/CourseTableRow";
const CoursesScreen = ({ picture, email, name, role }) => {
  // alert(email);
  //    const [employeeEmail,setEmployeeEmail]=useState(email)
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [lastUpdated, setLastUpdated] = useState("");
  const [currentlyEditing, setCurrentlyEditing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [allCourses, setAllCourses] = useState([]);
  const [courseIdList, setCourseIdList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const queryParams = { email: email };
        const response = await instance.get("/employee/employeeByEmail", {
          params: queryParams,
        });
        setEmployeeDetails(response.data.data);

        setCourseIdList(response.data.data?.courseIds);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [refresh, email]);
  useEffect(() => {
    (async () => {
      try {
        // Fetching employee courses with the list of course ids from the employeeDetails
        const courseList = await instance.post("/course/getEmployeeCourses", {
          courses: courseIdList,
        });
        console.log("This is course list =>", courseList);
        setAllCourses(courseList?.data?.data);
        const dateArray = courseList?.data?.data?.map((item) => {
          if (!isNaN(new Date(item?.updatedAt))) {
            return new Date(item?.updatedAt);
          } else return;
        });
        console.log(
          "this is date",
          dateArray.filter((item) => item !== undefined)
        );
        const latestTimestamp = new Date(
          Math.max(...dateArray.filter((item) => item !== undefined))
        );
        // const latestTimestamp = new Date(Math.max(...dateArray));
        console.log(latestTimestamp);
        const updatedAt = formatDate(latestTimestamp);
        setLastUpdated(updatedAt);
        // const dateArray = employeeDetails?.map(item => new Date(item.updatedAt));
        // const latestTimestamp = new Date(Math.max(...dateArray));
        // const updatedAt=formatDate(new Date(employeeDetails?.updatedAt));
        // setLastUpdated(updatedAt);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [employeeDetails, refresh]);

  const handleToggleEdit = (rowId) => {
    setCurrentlyEditing(rowId);
  };

  return (
    <div className="main-content">
      <Navbar name={name} role={role} picture={picture} userData="User" />
      <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8 ">
        <div className="container-fluid  ">
          <div className="header-body  ">
            <EmployeeCard
              setRefresh={setRefresh}
              employeeDetails={employeeDetails}
              lastUpdated={lastUpdated}
            />
          </div>
        </div>
        {/* ------ Add Employee -------- */}

        {/* --------- Filter ------------- */}

        {/* ...................................... */}
      </div>
      <div className="container-fluid mt--7">
        {/* Table */}
        <div className="row">
          <div className="col">
            <div className="card shadow">
              <div className="card-header border-0">
                <h3 className="mb-0">Courses</h3>
              </div>
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Course Name</th>
                      <th scope="col">Course Date</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCourses?.map((item, index) => {
                      if (item.deleted) return null;
                      return (
                        <CourseTableRow
                          userId={employeeDetails?._id}
                          key={index}
                          item={item}
                          id={item._id}
                          isEditing={currentlyEditing === item._id}
                          refresh={refresh}
                          onToggleEdit={handleToggleEdit}
                          setRefresh={setRefresh}
                        />
                      );
                    })}

                    <AddCourse
                      userId={employeeDetails?._id}
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
      </div>
    </div>
  );
};

export default CoursesScreen;
